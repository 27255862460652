import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import "./CardWithMapStyles.css";
import plus from "../Assets/svgs/plus.svg";
import minus from "../Assets/svgs/minus.svg";

const CardWithMap = () => {
  const [zoom, setZoom] = useState(16); // Initial zoom level (1 is default)
  const [center, setCenter] = useState(3, 53); // Initial center coordinates
  const [locations, setLocations] = useState([]); // State for locations with counts

  // Sample data for locations with counts
  const initialLocations = [
    { coordinates: [12.4964, 41.9028], count: 5 }, // Rome, Italy with count 5
    { coordinates: [2.3522, 48.8566], count: 3 }, // Paris, France with count 3
    { coordinates: [-0.1276, 51.5074], count: 8 }, // London, UK with count 8
    { coordinates: [-74.006, 40.7128], count: 6 }, // New York, USA with count 6
    // Add more locations and counts as needed
  ];

  useEffect(() => {
    // Set initial locations
    setLocations(initialLocations);

    // Calculate the bounding box of all locations
    const lats = initialLocations.map((location) => location.coordinates[1]);
    const longs = initialLocations.map((location) => location.coordinates[0]);

    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLong = Math.min(...longs);
    const maxLong = Math.max(...longs);

    // Calculate the center of the bounding box
    const calculatedCenter = [(minLong + maxLong) / 2, (minLat + maxLat) / 2];

    // Calculate the appropriate zoom level
    const latRange = maxLat - minLat;
    const longRange = maxLong - minLong;

    // Determine the zoom level to fit all markers within the map view
    const calculatedZoom = Math.min(150 / latRange, 150 / longRange);

    // Set the center and zoom level
    setCenter(calculatedCenter);
    setZoom(calculatedZoom);
  }, []);

  const handleZoomIn = () => {
    console.log(zoom, "zoom");
    setZoom((prevZoom) => prevZoom + 0.5); // Increase zoom level
  };

  const handleZoomOut = () => {
    console.log(zoom, "zoom");
    setZoom((prevZoom) => prevZoom - 0.5); // Decrease zoom level
  };

  return (
    <Card className="reported_incidents rounded_16 border-0 overflow-hidden">
      <Card.Body className="pb-0" style={{ flex: "unset" }}>
        <h4 className="fw-bold mb-0">Reported Incidents</h4>
      </Card.Body>
      <span className="px-3 mb-2 colorgrey">Past 7 Days</span>
      <div className="map-container position-relative">
        <ComposableMap projection="geoMercator" height={500} width={700}>
          <ZoomableGroup center={[3, 53]} zoom={16}>
            <Geographies geography="https://martinjc.github.io/UK-GeoJSON/json/eng/topo_eer.json">
              {({ geographies }) =>
                geographies.map((geo) => {
                  console.log("geo", geo);
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#41E1FF"
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
          <Marker coordinates={[-74.006, 40.7128]}>
            <circle r={8} fill="#F53" />
          </Marker>
        </ComposableMap>

        {/* Zoom controls */}
        <div className="d-flex justify-content-center mt-2 plus_minus_block">
          <Button
            className="background-transparent border-0 border-bottom-dark"
            onClick={handleZoomIn}
          >
            <img src={plus} alt="Zoom In" />
          </Button>
          <Button
            className="background-transparent border-0"
            onClick={handleZoomOut}
          >
            <img src={minus} alt="Zoom Out" />
          </Button>
        </div>

        {/* Location block */}
        {/* <div className="location_block">
          <span>Location</span>
          <span>
            <b>2344</b>
          </span>
        </div>
            */}
      </div>
    </Card>
  );
};

export default CardWithMap;
