// import React, { useState } from "react";
// import { format } from "date-fns";
// import "./Carousel.css"; // Import CSS for styling

// const HorizontalMonthSlider = () => {
//   // Generate array of months for the slider
//   const currentDate = new Date();
//   const months = [];
//   const initialMonthIndex = 2; // Index of the current month (middle one)

//   // Populate the months array with 5 months including the current month
//   for (let i = -2; i <= 2; i++) {
//     const date = new Date(currentDate);
//     date.setMonth(currentDate.getMonth() + i);
//     months.push(date);
//   }

//   console.log(months, "months are");

//   const [currentMonthIndex, setCurrentMonthIndex] = useState(initialMonthIndex);

//   return (
//     <div className="horizontal-month-slider">
//       <div className="months-container">
//         {months.map((month, index) => (
//           <div
//             key={index}
//             className={`month ${
//               index === currentMonthIndex ? "current-month" : "other-month"
//             }`}
//             onClick={() => setCurrentMonthIndex(index)}
//           >
//             {format(month, "MMM")}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default HorizontalMonthSlider;

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import "./Carousel.css"; // Import CSS for styling

const HorizontalMonthSlider = ({ months, setradialBardata }) => {
  const [currentMonthIndex, setCurrentMonthIndex] = useState(4);

  return (
    <div className="horizontal-month-slider">
      <div className="months-container">
        {months?.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className={`month ${
              index === currentMonthIndex ? "current-month" : "other-month"
            }`}
            onClick={() => {
              setCurrentMonthIndex(index);
              const formattedPercentageChange = item.percentageChange.toFixed(3); // Format to three digits after the decimal

              switch (item.month) {
                case "Apr":
                  setradialBardata((prev) => ({
                    ...prev,
                    series: [item.value],
                    options: {
                      ...prev?.options,
                      labels: [`${formattedPercentageChange}%`],
                      plotOptions: {
                        ...prev?.plotOptions,
                        radialBar: {
                          ...prev?.radialBar,
                          dataLabels: {
                            ...prev.dataLabels,
                            name: {
                              ...prev.name,
                              color:
                                item?.percentageChange > 0
                                  ? "#00FF00"
                                  : "#FF0000",
                            },
                          },
                        },
                      },
                    },
                  }));
                  break;
                case "May":
                  setradialBardata((prev) => ({
                    ...prev,
                    series: [item.value],
                    options: {
                      ...prev?.options,
                      labels: [`${item?.percentageChange}%`],
                      plotOptions: {
                        ...prev?.plotOptions,
                        radialBar: {
                          ...prev?.radialBar,
                          dataLabels: {
                            ...prev.dataLabels,
                            name: {
                              ...prev.name,
                              color:
                                item?.percentageChange > 0
                                  ? "#00FF00"
                                  : "#FF0000",
                            },
                          },
                        },
                      },
                    },
                  }));
                  break;
                default:
                  setradialBardata((prev) => ({
                    ...prev,
                    series: [item.value],
                    options: {
                      ...prev?.options,
                      labels: [`${item?.percentageChange}%`],
                      plotOptions: {
                        ...prev?.plotOptions,
                        radialBar: {
                          ...prev?.radialBar,
                          dataLabels: {
                            ...prev.dataLabels,
                            name: {
                              ...prev.name,
                              color: "#FF0000",
                            },
                          },
                        },
                      },
                    },
                  }));
              }
            }}
          >
            {item.month}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalMonthSlider;
