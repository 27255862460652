import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import DataTableComponent from "../../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReports,
  fetchReportsListingData,
} from "../../redux/slice/ReportsListingSlice";
import Loader from "../../components/Common/Loader";
import "./Reports.css";

function Reports() {
  const dispatch = useDispatch();
  const { status, data: ReportsManagementData } = useSelector(
    (state) => state.REPORTSLISTING
  );
  const { status2, data: ReportsData } = useSelector(
    (state) => state.REPORTSLISTING
  );

  useEffect(() => {
    dispatch(fetchReportsListingData());
    dispatch(fetchReports());
  }, [dispatch]);

  // Define columns for DataTableComponent
  const reportsColumns = [
    {
      name: "Report Name",
      selector: (row) => row.report_name,
    },
    {
      name: "Frequency",
      selector: (row) => `${row.frequency_units} x ${row.how_often}`,
    },
    {
      name: "Recipients",
      selector: (row) => row.recipients,
    },
    // {
    //     name: "Last Sent",
    //     selector: (row) => row.start_on,
    // },
    {
      name: "Last Sent",
      selector: (row) => {
        const date = new Date(row.start_on);
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return `${day} ${months[monthIndex]} ${year}`;
      },
      sortable: true,
    },
  ];

  const userData = ReportsData || [];
  console.log(userData, "userData");

  return (
    <div className="reports">
      {status === "loading" && <Loader />}
      <Row>
        <Col md={8} className="reports_table">
          <DataTableComponent
            columns={reportsColumns}
            data={ReportsData}
            title="Reports"
          />
        </Col>

        <Col md={4}>
          <Card style={{ border: "none" }}>
            <h3 className="listing text-center border-bottom py-4">
              Report Groups
            </h3>
            <p className="text-center">No records Found</p>{" "}
            {/* Apply Bootstrap's text-center class */}
            {/* The rest of the code is commented out or omitted */}
          </Card>
        </Col>

        {/* {status === "loading" && <p>Loading...</p>}
                        {status === "error" && <p>Error fetching data.</p>}
                        {status === "idle" && Array.isArray(ReportsManagementData) && ReportsManagementData.length > 0 && (
                            <div>
                                {ReportsManagementData?.map((group) => (
                                    <div className="title-box mb-2" key={group._id}>
                                        <div className="d-flex justify-content-between px-3 pb-2">
                                            <p className="mb-0">{group.group_name}</p>
                                        </div>
                                        <div className="person-box">
                                            <b>Directors</b>
                                            <p className="mt-3 border-top border-bottom py-3">
                                                {group.group_member?.map((member) => (
                                                    <span key={member._id}>
                                                        {member.full_name},{" "}
                                                    </span>
                                                ))}
                                            </p>
                                            <div className="d-flex mt-2 edit_delete">
                                                <span>
                                                    <FaEdit />
                                                    Edit
                                                </span>
                                                <span variant="danger">
                                                    <FaTrash />
                                                    Delete
                                                </span>
                                            </div>
                                        </div>
                                        <p className="mt-4 px-3">Sales Reps</p>
                                    </div>
                                ))}
                            </div>
                        )} */}
      </Row>
    </div>
  );
}

export default Reports;
