import React from "react";
import { Button, Modal } from "react-bootstrap";

const InfoModal = ({ hide, show, listingData, title }) => {
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>{title} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <h6 className="mb-0" style={{ width: "30%" }}>
            Full Name:{" "}
          </h6>
          <span
            className="ms-3 d-block text_gray text-capitalize"
            style={{ width: "70%" }}
          >
            {" "}
            {listingData.first_name + listingData.last_name}
          </span>
        </div>

        <div className="d-flex mt-2 align-items-center">
          <h6 className="mb-0" style={{ width: "30%" }}>
            Company Name:
          </h6>
          <span
            className="ms-3 text_gray text-capitalize"
            style={{ width: "70%" }}
          >
            {" "}
            {listingData.company_name}
          </span>
        </div>

        <div className="d-flex mt-2 align-items-center">
          <h6 className="mb-0" style={{ width: "30%" }}>
            Job Title:
          </h6>
          <span
            className="ms-3 text_gray text-capitalize"
            style={{ width: "70%" }}
          >
            {" "}
            {listingData.job_title}
          </span>
        </div>
        <div className="d-flex mt-2 align-items-center">
          <h6 className="mb-0" style={{ width: "30%" }}>
            Assigned Role:
          </h6>
          <span
            className="ms-3 text_gray text-capitalize"
            style={{ width: "70%" }}
          >
            {" "}
            {listingData.assigned_role}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          Close
        </Button>
        <Button variant="primary" className="d-none" onClick={hide}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;