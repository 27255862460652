import React, { useEffect, useState } from "react";
import "./index.css";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsManagementData } from "../../../redux/slice/ProductManagementSlice";
import { fetchDistributorManagementData } from "../../../redux/slice/DistributionRegistrationSlice";
import { useFormik } from "formik";
import {
  registerBatchKit,
  setPdfData,
} from "../../../redux/slice/KitManagementSlice";
import ButtonLoader from "../../../components/Common/ButtonLoader";
import { StatusCode } from "../../../services/helper";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchBatchKitsData } from "../../../redux/slice/BatchKits";
import ValidationSchema from "../../../components/Common/ValidationScema";

const CreateKit = () => {
  const initialValues = {
    product_name: "",
    brand: "",
    kit_ref_id: "",
    product_code: "",
    lot_number: "",
    product_id: "",
    batch_number: "",
    kit_picture: "",
    van: "",
    expiry_date: null,
    distributor_id: "",
    quantity: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [place, setPlace] = useState(false);
  const ProductManagementData = useSelector((state) => state.PRODUCTMANAGEMENT);
  const { BatchKitsData } = useSelector((state) => state.BATCHKITS);
  const [filteredProductNames, setFilteredProductNames] = useState(
    ProductManagementData?.ProductManagementData?.data?.products
  );
  const [item_not_expire, setitem_not_expire] = useState(false);

  // const [productsData, setProductsData] = useState([]);
  const [productsData, setProductsData] = useState(
    BatchKitsData?.data?.relatedProducts.map((product) => ({
      ...product,
      item_not_expire: false, // Set item_not_expire to false by default
    }))
  );
  const [showProd, setShowProd] = useState(false);

  const [parentProduct, setParentProduct] = useState({});

  useEffect(() => {
    setProductsData(BatchKitsData?.data?.relatedProducts);
  }, [BatchKitsData]);

  const { status } = useSelector((state) => state.KITMANAGEMENT);
  const {
    values,
    setValues,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: ValidationSchema.createkit,
    onSubmit: async (values, action) => {
      const res = await dispatch(registerBatchKit(values));
      if (res?.payload?.status === 200) {
        dispatch(setPdfData(res?.payload?.data));
        navigate("/kitpdf");
      }
    },
  });

  useEffect(() => {
    dispatch(fetchProductsManagementData());
    dispatch(fetchDistributorManagementData());
  }, []);
  // const handleToggleChange = (index) => {
  //   const updatedProducts = [...productsData];
  //   updatedProducts[index] = {
  //     ...updatedProducts[index],
  //     item_not_expire: !updatedProducts[index].item_not_expire,

  //   };
  //   setProductsData(updatedProducts);
  // };
  const handleToggleChange = (index) => {
    const updatedProducts = [...productsData];
    updatedProducts[index] = {
      ...updatedProducts[index],
      item_not_expire: !updatedProducts[index].item_not_expire,
      expiry_date: !updatedProducts[index].item_not_expire
        ? ""
        : updatedProducts[index].expiry_date, // Clear expiry_date if item_not_expire is true
    };
    setProductsData(updatedProducts);

    // Find the minimum expiry_date among all products that do expire
    let minExpiryDate = Infinity;

    updatedProducts.forEach((product) => {
      if (!product.item_not_expire && product.expiry_date) {
        const expiryTime = new Date(product.expiry_date).getTime();
        if (expiryTime < minExpiryDate) {
          minExpiryDate = expiryTime;
        }
      }
    });

    // Update values.expiry_date if the minimum expiry_date is earlier
    if (minExpiryDate !== Infinity) {
      const minExpiryDateString = new Date(minExpiryDate)
        .toISOString()
        .slice(0, 10); // Convert minimum expiry_date to string
      setValues({
        ...values,
        expiry_date: minExpiryDateString,
      });
    } else {
      // No valid expiry_date found, clear values.expiry_date
      setValues({
        ...values,
        expiry_date: "",
      });
    }
  };

  
  useEffect(() => {
    if (values.product_code) {
      setShowProd(false);
      const filteredProducts = ProductManagementData?.ProductManagementData?.data?.products.filter(
        (product) =>
          product.product_code &&
          typeof product.product_code === 'string' && // Check if product_code is a string
          typeof values.product_code === 'string' && // Check if values.product_code is a string
          product.product_code
            .toLowerCase()
            .includes(values.product_code.toLocaleLowerCase())
      );
      console.log(filteredProducts, "filteredProducts")
      setFilteredProductNames(filteredProducts);
    } else {
      setFilteredProductNames([]);
   

    }
  }, [values.product_code, ProductManagementData]);

  useEffect(() => {
    if (BatchKitsData?.data?.relatedProducts) {
      const currentDate = new Date().getTime();
      let minExpiryDate = null;
      filteredProductNames.forEach((product) => {
        const expiryTime = new Date(product.expiry_date).getTime();
        if (!minExpiryDate || expiryTime < minExpiryDate) {
          if (expiryTime > currentDate) {
            minExpiryDate = expiryTime;
          }
        }
      });
      if (minExpiryDate) {
        const minExpiryDateObj = new Date(minExpiryDate);
        const formattedMinExpiryDate = minExpiryDateObj
          .toISOString()
          .split("T")[0];
        setValues({
          ...values,
          expiry_date: formattedMinExpiryDate,
        });
      }
    }
  }, [BatchKitsData]);
  // useEffect(() => {
  //   if (BatchKitsData?.data?.relatedProducts) {
  //     const currentDate = new Date().getTime();
  //     let minExpiryDate = null;
  //     const updatedProductsData = BatchKitsData.data.relatedProducts.map(product => {
  //       const expiryTime = new Date(product.expiry_date).getTime();
  //       if (!minExpiryDate || expiryTime < minExpiryDate) {
  //         if (expiryTime > currentDate) {
  //           minExpiryDate = expiryTime;
  //         }
  //       }
  //       return {
  //         ...product,
  //         expiry_date: product.expiry_date
  //       };
  //     });
  //     setProductsData(updatedProductsData);
  //     if (minExpiryDate) {
  //       const minExpiryDateObj = new Date(minExpiryDate);
  //       const formattedMinExpiryDate = minExpiryDateObj.toISOString().split("T")[0];
  //       setValues({
  //         ...values,
  //         expiry_date: formattedMinExpiryDate,
  //       });
  //     }
  //   }
  // }, [BatchKitsData]);

  const handleProductNameClick = async (element) => {
    setShowProd(true);
    setFieldValue("product_code", element.product_code);
    setParentProduct(element);
    setFilteredProductNames([]);

    await dispatch(fetchBatchKitsData(element.kit_ref_id));
  };

  const onChangeToNumber = (value) => {
    if (typeof value === "number") return value;
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && isFinite(numericValue)) {
      return numericValue;
    }
    return value;
  };

  const handleBatchCreate = () => {
    // console.log(filteredProductNames, "filteredProductNames===>>");
    const selectedProduct = {
      products: productsData,
      product_code: parentProduct.product_code,
      lot_number: values.lot_number,
      expiry_date: values.expiry_date,
      kit_ref_id: parentProduct.kit_ref_id,
      _id: parentProduct._id,
      brand: parentProduct.brand,
      kit_picture: parentProduct.kit_picture,
      product_name: parentProduct.product_name,
      quantity: values.quantity,
    };
    console.log(selectedProduct, "`selectedProduct`");
    if (Object.keys(errors).length > 0) {
      console.log(errors, "errors");
      return;
    } else {
      console.log("else condition works");
      const selectedProduct = {
        products: productsData,
        product_code: parentProduct.product_code,
        lot_number: values.lot_number,
        expiry_date: values.expiry_date,
        kit_ref_id: parentProduct.kit_ref_id,
        _id: parentProduct._id,
        brand: parentProduct.brand,
        kit_picture: parentProduct.kit_picture,
        product_name: parentProduct.product_name,
        quantity: values.quantity,
      };
      console.log(selectedProduct, "`selectedProduct`");

      dispatch(registerBatchKit(selectedProduct))
        .then((res) => {
          if (res?.payload?.status === 200) {
            dispatch(setPdfData(res?.payload?.data));
            navigate("/kitpdf");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  // const handleBatchCreate = () => {
  //   if (Object.keys(errors).length > 0) {
  //     console.log(errors, "errors");
  //     return;
  //   } else {
  //     const selectedProduct = {
  //       products: productsData.map(product => ({
  //         ...product,
  //         expiry_date: product.expiry_date // Include expiry date
  //       })),
  //       lot_number: values.lot_number,
  //       expiry_date: values.expiry_date,
  //       kit_ref_id: parentProduct.kit_ref_id,
  //       _id: parentProduct._id,
  //       brand: parentProduct.brand,
  //       kit_picture: parentProduct.kit_picture,
  //       product_name: parentProduct.product_name,
  //       quantity: values.quantity,
  //     };

  //     dispatch(registerBatchKit(selectedProduct))
  //       .then((res) => {
  //         if (res?.payload?.status === 200) {
  //           dispatch(setPdfData(res?.payload?.data));
  //           navigate("/kitpdf");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }
  // };

  // const onExpiryChange = (e, index) => {
  //   const newDate = e.target.value;
  //   const updatedProducts = [...productsData];
  //   updatedProducts[index] = {
  //     ...updatedProducts[index],
  //     expiry_date: newDate,
  //     item_not_expire: !newDate, // Set item_not_expire to true if no expiry date is selected
  //   };

  //   // Update productsData state with the modified product
  //   setProductsData(updatedProducts);

  //   // Find the minimum expiry_date among all products that do expire
  //   let minExpiryDate = Infinity;

  //   updatedProducts.forEach((product) => {
  //     if (!product.item_not_expire && product.expiry_date) {
  //       const expiryTime = new Date(product.expiry_date).getTime();
  //       if (expiryTime < minExpiryDate) {
  //         minExpiryDate = expiryTime;
  //       }
  //     }
  //   });

  //   // Update values.expiry_date if the minimum expiry_date is earlier
  //   if (minExpiryDate !== Infinity) {
  //     const minExpiryDateString = new Date(minExpiryDate).toISOString().slice(0, 10); // Convert minimum expiry_date to string
  //     setValues({
  //       ...values,
  //       expiry_date: minExpiryDateString,
  //     });
  //   } else {
  //     // No valid expiry_date found, clear values.expiry_date
  //     setValues({
  //       ...values,
  //       expiry_date: '',
  //     });
  //   }
  // };
  const onExpiryChange = (e, index) => {
    const newDate = e.target.value;
    const updatedProducts = [...productsData];
    updatedProducts[index] = {
      ...updatedProducts[index],
      expiry_date: newDate,
      item_not_expire: !newDate, // Set item_not_expire to true if no expiry date is selected
    };

    // Update productsData state with the modified product
    setProductsData(updatedProducts);

    // Find the minimum expiry_date among all products that do expire
    let minExpiryDate = Infinity;

    updatedProducts.forEach((product) => {
      if (!product.item_not_expire && product.expiry_date) {
        const expiryTime = new Date(product.expiry_date).getTime();
        if (expiryTime < minExpiryDate) {
          minExpiryDate = expiryTime;
        }
      }
    });

    // Update values.expiry_date if the minimum expiry_date is earlier
    if (minExpiryDate !== Infinity) {
      const minExpiryDateObj = new Date(minExpiryDate);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const minExpiryDateString = minExpiryDateObj.toLocaleDateString(
        "en-US",
        options
      );

      // Split the formatted date and rearrange it with the day first
      const dateParts = minExpiryDateString.split(" ");
      const rearrangedDate = `${dateParts[1]} ${dateParts[0]}, ${dateParts[2]}`;

      setValues({
        ...values,
        expiry_date: rearrangedDate,
      });
    } else {
      // No valid expiry_date found, clear values.expiry_date
      setValues({
        ...values,
        expiry_date: "",
      });
    }
  };


  return (
    <>
      <div className="createkit">
        <h3 className="text-center border-bottom pb-4 pt-2">
          Batch Create Kits
        </h3>
        <h6 className="text-center mt-2 mb-5 mt-4">Enter Product Details</h6>
        <div className="kitformData">
          <Form onSubmit={handleSubmit}>
            <div className="row">
            <div className="col-9">
      <Form.Group className="mb-3" controlId="formBasicProduct">
        <Form.Control
          type="text"
          name="product_code"
          className="cursor"
          onChange={handleChange}
          value={values.product_code}
          placeholder="Product Code"
          onBlur={handleBlur}
          autoComplete="on"
        />
      </Form.Group>
      {errors.product_code && touched.product_code ? (
        <p className="text-danger">{errors.product_code} </p>
      ) : null}
      {parentProduct.product_code && values.product_code && (
        <p>
         {parentProduct.product_code} - {parentProduct.product_name}
        </p>
      )}
    </div>

              <div className="col-3">
                <Form.Group className="mb-3" controlId="formBasicLotNumber">
                  <Form.Control
                    type="text"
                    name="lot_number"
                    onChange={handleChange}
                    value={values.lot_number}
                    onBlur={handleBlur}
                    placeholder="Lot Number"
                  />
                </Form.Group>
                {errors.lot_number && touched.lot_number ? (
                  <p className="text-danger">{errors.lot_number} </p>
                ) : null}
              </div>
            </div>
            <div className="row">
             
              <div className="col-12 batch_table">
                <div style={{ maxHeight: "33vh", overflowY: "auto" }}>
                  <Table responsive>
                    <thead></thead>
                    <tbody>
                      {showProd
                        ? productsData &&
                          productsData?.map((curElm, index) => (
                            <tr key={index}>
                              <td>
                                {/* {curElm.product_code}-{curElm.product_name} */}
                                <td>{curElm.product_code}-{curElm.product_name}</td>
                              </td>

                              <td>
                                <Form.Control
                                  type="number"
                                  value={curElm.quantity}
                                  onChange={(e) => {
                                    let newValue = parseInt(e.target.value);
                                    // Prevent setting negative or zero values
                                    newValue = newValue > 0 ? newValue : "";
                                    const updatedProducts = [...productsData];
                                    updatedProducts[index] = {
                                      ...updatedProducts[index],
                                      quantity: newValue,
                                    };
                                    setProductsData(updatedProducts);
                                  }}
                                  size="sm" // Set the size to small
                                  style={{ width: "70px" }}
                                />
                                {curElm.quantity <= 0 && (
                                  <Form.Control.Feedback type="invalid">
                                    Quantity must be greater than zero.
                                  </Form.Control.Feedback>
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  value={curElm.lot_number}
                                  placeholder="Lot Number"
                                  onChange={(e) => {
                                    let newValue = parseInt(e.target.value);
                                    // Prevent setting negative or zero values
                                    newValue = newValue > 0 ? newValue : "";
                                    const updatedProducts = [...productsData];
                                    updatedProducts[index] = {
                                      ...updatedProducts[index],
                                      lot_number: newValue,
                                    };
                                    setProductsData(updatedProducts);
                                  }}
                                />
                                {curElm.lot_number <= 0 && (
                                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                )}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <Form.Check
                                  type="switch"
                                  id={`expiry-toggle-${index}`}
                                  label=""
                                  checked={curElm.item_not_expire}
                                  onChange={() => handleToggleChange(index)}
                                />
                              </td>
                              {/* <td>
                                {!curElm.item_not_expire && (
                                <Form.Control
                                  min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                                  type="date"
                                  value={curElm.expiry_date}
                                  onChange={(e) =>
                                    onExpiryChange(e, index, curElm.expiry_date)
                                  
                                  }
                                  isInvalid={
                                    errors &&
                                    touched &&
                                    errors.expiry_date &&
                                    touched.expiry_date
                                  }
                                  style={{width:"145px"}}
                                />

                              )} 
                                <Form.Control.Feedback type="invalid">
                                  {errors.expiry_date}
                                </Form.Control.Feedback>
                              </td> */}
                              <td>
                                {!curElm.item_not_expire ? (
                                  <Form.Control
                                    min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                                    type="date"
                                    value={curElm.expiry_date}
                                    onChange={(e) =>
                                      onExpiryChange(
                                        e,
                                        index,
                                        curElm.expiry_date
                                      )
                                    }
                                    isInvalid={
                                      errors &&
                                      touched &&
                                      errors.expiry_date &&
                                      touched.expiry_date
                                    }
                                    style={{ width: "145px" }}
                                  />
                                ) : (
                                  <span style={{color:"red"}}>Doesn't Expire</span>
                                )}
                                <Form.Control.Feedback type="invalid">
                                  {errors.expiry_date}
                                </Form.Control.Feedback>
                              </td>
                            </tr>
                          ))
                        : filteredProductNames?.map((curElm, index) => (
                            <tr key={index} className="filtered_row">
                              <td
                                onClick={() => handleProductNameClick(curElm)}
                              >
                                {curElm.product_code}-{curElm.product_name}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </div>
              </div>

            </div>
            <div className="row mt-4">
              <div className="col-9">
                <Form.Group className="mb-3" controlId="formBasicDate">
                  <Form.Control
                    onFocus={() => setPlace(true)}
                    type={place ? "date" : "text"}
                    name="expiry_date"
                    placeholder=" Kit Expiry Date"
                    onChange={handleChange}
                    value={values.expiry_date}
                    onBlur={() => setPlace(false) & handleBlur}
                    className="cursor"
                    // disabled={!place}
                  />
                </Form.Group>
                {errors.expiry_date ? (
                  <p className="text-danger">{errors.expiry_date} </p>
                ) : null}
              </div>
              <div className="col-3">
                <Form.Group className="mb-3" controlId="formBasicQuantity">
                  <Form.Control
                    type="text"
                    name="quantity"
                    onChange={handleChange}
                    value={onChangeToNumber(values.quantity)}
                    onBlur={handleBlur}
                    placeholder="Batch Quantity"
                  />
                </Form.Group>
                {errors.quantity && touched.quantity ? (
                  <p className="text-danger">{errors.quantity} </p>
                ) : null}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 text-end">
                <NavLink to="/kit" className="btn btn-dark me-3" type="button">
                  Cancel
                </NavLink>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleBatchCreate}
                >
                  {status === StatusCode.LOADING ? (
                    <ButtonLoader />
                  ) : (
                    "Batch Create"
                  )}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateKit;
