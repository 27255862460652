// data.js

const tableData = [
  {
    id: 1,
    product: "Product 1",
    brand: "Reliance Medical",
    contents: "Contents 1",
    lotNumber: "LOT001",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    registeredTo: "A Company",
    industry: "Industry",
    location: "Location",
    role: "Staff",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    status: "Approved to Publish ",
    reportName:"Director’s evaluation",
    frequency:"1 x Monthly",
    recipients:"5",
    lastSent:"12 Jan 2024 ",

    notification:"The topic of the question",
    notificationTime:"3 mins ago",
     actions: [{ name: "Complaint", type: "primary" }],

  },
  {
    id: 2,
    product: "Product 2",
    brand: "Brand B",
    contents: "Contents 2",
    lotNumber: "LOT002",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    registeredTo: "A Company",
    industry: "Industry",
    role: "Staff",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: "Expiring", type: "secondary" }],
    images:''
  },
  {
    id: 3,
    product: "Product 3",
    brand: "Brand ",
    contents: "Contents 3",
    lotNumber: "LOT003",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: " Update", type: "tertiary" }],
  },
  {
    id: 4,
    product: "Product 3",
    brand: "Brand ",
    contents: "Contents 3",
    lotNumber: "LOT003",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: " Update", type: "tertiary" }],
  },
  {
    id: 5,
    product: "Product 4",
    brand: "Brand ",
    contents: "Contents 4",
    lotNumber: "LOT004",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: "Expiring", type: "secondary" }],
  },

  {
    id: 6,
    product: "Product 1",
    brand: "Brand A",
    contents: "Contents 1",
    lotNumber: "LOT001",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: "Complaint", type: "primary" }],
  },

  {
    id: 7,
    product: "Product 4",
    brand: "Brand ",
    contents: "Contents 4",
    lotNumber: "LOT004",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: "Expiring", type: "secondary" }],
  },
  {
    id: 8,
    product: "Product 4",
    brand: "Brand ",
    contents: "Contents 4",
    lotNumber: "LOT004",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: "Expiring", type: "secondary" }],
  },
  {
    id: 9,
    product: "Product 9",
    brand: "Brand ",
    contents: "Contents 4",
    lotNumber: "LOT004",
    area: "Kitchen",
    batch: "AHD39BDL",
    expiry: "22 Feb 2024",
    location: "Location",
    role: "Staff",
    registeredTo: "A Company",
    industry: "Industry",
    lastName: "Last Name",
    jobTitle: "Software Engineer",
    firstName: "First Name",
    subject: "Title of Post",
    category: "How To Video",
    companyName: "Company Name",
    country: "UK",
    county: "Surrey",
    superAdmin: "John Jackson ",
    approver: "Ariel Corts",
    type: "Article",
    scheduled: "14 February 2024",
    status: "Approved to Publish ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    reportName: "Director’s evaluation",
    frequency: "1 x Monthly",
    recipients: "5",
    lastSent: "12 Jan 2024 ",
    notification: "The topic of the question",
    notificationTime: "3 mins ago",
    actions: [{ name: "Expiring", type: "secondary" }],
  },
  //
  // Add more data as needed
];
// const imageData=[
//   { 
//      images: "https://tradeportal.reliancemedical.co.uk/images/product/source/101_Wallet.jpg", // Add the image path here

//     },
//     { 
//       images: "https://tradeportal.reliancemedical.co.uk/images/product/source/101_Wallet.jpg", // Add the image path here
 
//      }
 


// ]
export default tableData;
