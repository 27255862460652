import React from "react";

const Screen5 = () => {
  return (
    <div className="screenfive">
      <h4 className="text-center">Record Creation Results </h4>
      <hr />
      <div className="mt-4">
        <p className="text-center mt-2">
          [##] records have been successfully imported.{" "}
        </p>
        <p className="text-center mt-4">
          [##] records with duplicated EAN codes were skipped.{" "}
        </p>
        <p className="text-center mt-4">[##] records were not created. </p>
      </div>
    </div>
  );
};

export default Screen5;
