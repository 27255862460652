import React, { useEffect, useState } from "react";

import "./index.css";

import icon from "../../../Assets/Profile/profileicon/Icon.png";
import DataTableComponent from "../../../components/DataTable";
import tableData from "../../../data";

function DistributorClients() {
  const distColumns = [
    {
      name: "Company Name",
      selector: (row) => (
        <div className="products-wrapper">
          <img
            src={icon}
            alt={row.distributor_name}
            className="products-image"
          />
          <span>{row.companyName}</span>
        </div>
      ),
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },

    {
      name: "County",
      selector: (row) => row.county,
    },
    {
      name: "Super Admin",
      selector: (row) => row.role,
    },
    {
      name: "Approver",
      selector: (row) => row.approver,
    },
  ];


  return (
    <div className="product-management">
      <DataTableComponent
        title={"View Clients"}
        columns={distColumns}
        data={tableData}
        selectedRows
      />
    </div>
  );
}

export default DistributorClients;
