import React, { useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CustomPagination from "./Pagination";

const DataTableComponent = ({
  title,
  data,
  columns,
  selectedRows,
  clickedrow,
  checkboxchange,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  if (!data) {
    data = [];
  }

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  let displayedRange = `${
    indexOfFirstItem + 1
  }-${indexOfLastItem} of ${totalItems}`;

  if (currentItems.length < itemsPerPage) {
    displayedRange = `${indexOfFirstItem + 1}-${
      indexOfFirstItem + currentItems.length
    } of ${totalItems}`;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Card style={{ border: "none" }}>
      <div style={{ padding: "10px" }}>
        <h3 className="px-2 card_title">
          {title} ({displayedRange})
        </h3>

        <DataTable
          className=" table_block mt-3"
          highlightOnHover={true}
          selectableRowsHighlight={true}
          onRowClicked={clickedrow}
          onSelectedRowsChange={checkboxchange}
          columns={columns}
          pagination={false}
          customStyles={{
            table: {
              style: {
                border: "1px solid white",
              },
            },
            headCells: {
              style: {
                color: "#A8A8BD",
                fontSize: 16,
              },
            },
            rows: {
              style: {
                borderBottom: "1px solid #C5C5D3",
                padding: 20,
              },
            },
          }}
          data={currentItems || []}
          selectableRows={selectedRows}
        />
      </div>

      {/* Render custom pagination outside the scrolling div */}
      <CustomPagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onFirstPage={() => setCurrentPage(1)}
        onLastPage={() => setCurrentPage(totalPages)}
      />
    </Card>
  );
};

export default DataTableComponent;
