import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./LocationPopup.css";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { Form } from "react-bootstrap";
import DeleteConFirmationModal from "../../../components/Common/DeleteConFirmationModal";
import { useNavigate } from "react-router-dom";
import { deleteLocationData, updateLocationData } from "../../../redux/slice/BusinessProfileSlice";

const LocationPopup = ({ isOpen, onClose, selectedLocation }) => {
  const { status, BusinessProfileData } = useSelector(
    (state) => state.BUSINESSPROFILE
  );
  const [deleteconfirm, setDeleteConfirm] = useState(false);
  const [modalConfirmTitle, setModalConfirmTitle] = useState("");
  const navigate = useNavigate();
  const [removeId, setRemoveId] = useState([]);
  const dispatch = useDispatch();

  const userData = BusinessProfileData?.locations || [];
  
  const [data, setData] = useState({
    locationName: "",
    street: "",
    city: "",
    country: "",
    county: "",
    zip_code: "",
    country_code: "",
    number: "",
    email: "",
    assigned_admin_name: "",
    assigned_approver_name: ""
  });
  const { locationName, street, city, country, zip_code, number, email, county, assigned_admin_name, assigned_approver_name } = data;

  useEffect(() => {
    setData({
      locationName: selectedLocation?.location_name ?? "",
      street: selectedLocation?.street ?? "",
      city: selectedLocation?.city ?? "",
      country: selectedLocation?.country ?? "",
      county: selectedLocation?.county ?? "",
      zip_code: selectedLocation?.zip_code ?? "",
      country_code: selectedLocation?.country_code ?? "",
      number: selectedLocation?.telephone ?? "",
      email: selectedLocation?.business_email ?? "",
      assigned_admin_name: selectedLocation?.assigned_admin_name ?? "",
      assigned_approver_name: selectedLocation?.assigned_approver_name ?? ""
    });
  }, [selectedLocation]);


  const handleSaveChanges = () => {
    dispatch(updateLocationData({ id: selectedLocation._id, values: data }))
      .then(() => {
        console.log("Location data updated successfully");
        onClose();
      })
      .catch((error) => {
        console.error("Failed to update location data:", error);
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  };

  const removeUserHandler = async () => {
    if (removeId) {
      try {
        await dispatch(deleteLocationData(removeId));
        onClose();
      } catch (error) {
        console.error("Failed to delete location:", error);
      }
    }
  };

  const deletehandler = () => {
    if (selectedLocation?._id) {
      setRemoveId(selectedLocation._id); 
      setDeleteConfirm(true);
      setModalConfirmTitle("Are you sure you want to delete this location?");
    }
  };

  return (
    <Modal className="user_location_modal" show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="createlocation">
            <h3 className="text-center border-bottom py-3 fw-bold">
              Current Location
            </h3>
            <div className="locationformdata">
              <form>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label htmlFor="location_name">Location Name:</label>
                    <input
                      id="location_name"
                      className="form-control"
                      type="text"
                      name="locationName"
                      value={locationName}
                      onChange={handleChange}
                      placeholder="Location Name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label htmlFor="street">Street Address:</label>
                    <input
                      id="street"
                      className="form-control"
                      type="text"
                      name="street"
                      value={street}
                      onChange={handleChange}
                      placeholder="Street Address"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label>City:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="city"
                      value={city}
                      onChange={handleChange}
                      placeholder="City"
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label>County:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="county"
                      value={county}
                      onChange={handleChange}
                      placeholder="County"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label>Country:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="country"
                      value={country}
                      onChange={handleChange}
                      placeholder="Country"
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label>Zip Code:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="zip_code"
                      value={zip_code}
                      onChange={handleChange}
                      placeholder="Zip Code"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label htmlFor="email">Business Email:</label>
                    <input
                      id="email"
                      className="form-control"
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Business Email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Phone Number:</label>
                    <div className="phoneinput">
                      <PhoneInput
                        name="country_code"
                        country={""}
                        disableTyping={false}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-7 col-md-4 mb-3">
                      <Form.Group controlId="formBasicPhone">
                        <Form.Control
                          onKeyPress={(e) => {
                            if (
                              e.key === "e" ||
                              e.key === "E" ||
                              isNaN(Number(e.key))
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          name="number"
                          type="text"
                          pattern="[0-9]*"
                          value={number}
                          onChange={handleChange}
                          placeholder="Number"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Assigned Super Admin:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="assigned_admin_name"
                      value={assigned_admin_name}
                      onChange={handleChange}
                      placeholder="Assigned Super Admin"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Assigned Approver:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="assigned_approver_name"
                      value={assigned_approver_name}
                      onChange={handleChange}
                      placeholder="Assigned Approver"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSaveChanges}

        >
          Save Changes
        </button>
        <button onClick={deletehandler} className="btn-delete btn btn-danger">
          Delete
        </button>
        {deleteconfirm && (
          <DeleteConFirmationModal
            show={deleteconfirm}
            title={modalConfirmTitle}
            eventhandler={removeUserHandler}
            hide={() => setDeleteConfirm(false)}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default LocationPopup;
