import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MultiSelect } from "react-multi-select-component";
import "./index.css";
const ExportCsvFiles = ({ heading, mapArray, hide }) => {
  const [radiocheck, setRadioChecked] = useState(1);
  const [mappedArray, setMappedArray] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const newArray = mapArray?.slice(1);
    const mapArraywithlabel = newArray.map((curElm) => {
      return {
        label: curElm.label,
        value: curElm.value,
      };
    });

    setMappedArray(mapArraywithlabel);
  }, []);
  return (
    <div className="exportcsv">
      <h2 className="text-center">{heading} Records </h2>
      <hr />
      <h6 className="text-center">
        Choose the records you will like to export
      </h6>
      <hr />
      <div className="mt-3">
        <span
          className={radiocheck === 1 ? "customradio active" : "customradio"}
          onClick={() => {
            setRadioChecked(1);
            setSelected([]);
          }}
        ></span>
        <span className="customradiolabel">All records in this database</span>
      </div>
      <div className="mt-3">
        <span
          className={radiocheck === 2 ? "customradio active" : "customradio"}
          onClick={() => setRadioChecked(2)}
        ></span>
        <span className="customradiolabel">Within a search criteria</span>
        <div className="position-relative d-none">
          <input
            type="text"
            value={search}
            disabled={radiocheck === 2 ? false : true}
            onChange={(e) => setSearch(e.target.value)}
            className="customexportsearch form-control"
          />
          <span className="search_icon">
            <FiSearch size={25} />
          </span>
        </div>
        <div className="multi_export_file">
          <MultiSelect
            options={mappedArray || []}
            disabled={radiocheck === 2 ? false : true}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
        </div>
      </div>
      <hr className="mt-4" />

      <div className="exportbottom pt-1">
        <h5 className="mb-2">Record Creation date range</h5>
        <div className="d-flex">
          <div className="datefrom w-50 me-2">
            <input type="date" placeholder="From" className="form-control" />
          </div>
          <div className="dateto w-50">
            <input type="date" className="form-control" placeholder="To" />
          </div>
        </div>
        <div className="text-end mt-3 mb-2">
          <button
            className="btn btn-dark customexportcancelbutton"
            onClick={() => hide(false)}
          >
            Cancel{" "}
          </button>
          <button className="btn btn-primary customexportbutton">
            Export{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExportCsvFiles;
