import {
  FaBell,
  FaBellSlash,
  FaChartBar,
  FaCubes,
  FaFile,
  FaFoursquare,
  FaHome,
  FaJsSquare,
  FaMedkit,
  FaSignOutAlt,
  FaSquare,
  FaSquareFull,
  FaSquarespace,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import dashboardIcon from "../Assets/svgs/Vector (1).svg";
import UserIcon from "../Assets/svgs/Users.svg";
import Products from "../Assets/svgs/Cube.svg";
import Kits from "../Assets/svgs/Group 11.svg";
import Business from "../Assets/svgs/Vector.svg";
import Distributors from "../Assets/svgs/Users.svg";
import Reports from "../Assets/svgs/ChartBar.svg";
import Resources from "../Assets/svgs/File.svg";
import Bell from "../Assets/svgs/Bell.svg";
import { permissions } from "./helperFunction";

const {
  all,
  products,
  users,
  business,
  distributors,
  resource,
  notifications,
  dist_superadmin
} = permissions;

export const menus = [
  {
    permission: [all],
    title: "Dashboard",
    icon: <img src={dashboardIcon} />,
    link: "/",
  },
  {
    permission: [all,products],
    title: "Products",
    icon: <img src={Products} />,
    link: "/products",
  },
  {
    permission: [all,"kit managemant"],
    title: "Kit Management",
    icon: <img src={Kits} />,
    link: "/kit",
  },
  {
    permission: [all,users],
    title: "User Management",
    icon: <img src={UserIcon} />,
    link: "/users",
  },
  {
    permission: [all,business],
    title: "Business Profile",
    icon: <img src={Business} />,
    link: "/businessprofile",
  },
  {
    permission: [all, ],
    title: "Distributors",
    icon: <img src={Distributors} />,
    link: "/distributors",
  },
  {
    permission: [all],
    title: "Reports",
    icon: <img src={Reports} />,
    link: "/reports",
  },
  {
    permission: [all,resource],
    title: "Resources",
    icon: <img src={Resources} />,
    link: "/resource",
  },
  {
    permission: [all],
    title: "Message Center",
    icon: <img src={Bell} />,
    link: "/messaging",
  },
  // {
  //   title: "Notifications",
  //   icon: <img src={Bell} />,
  //   link: "/notifications",
  // },
];

export const distributormenus = [
  {
    permission: [dist_superadmin],

    title: "Dashboard",
    icon: <img src={dashboardIcon} />,
    link: "/distributor",
  },
  {
    permission: [dist_superadmin,products],

    title: "User Management",
    icon: <img src={Products} />,
    link: "/distributor/usermanagement",
  },
  {
    permission: [dist_superadmin,Business],

    title: "Business Profile",
    icon: <img src={Business} />,
    link: "/distributor/businessprofile",
  },
  {
    permission: [dist_superadmin,distributors],

    title: "Clients",
    icon: <img src={UserIcon} />,
    link: "/distributor/clients",
  },
  {
    permission: [dist_superadmin, notifications],

    title: "Notification",
    icon: <img src={Bell} />,
    link: "/distributor/notifilcation",
  },
];
